import { Component, Vue } from 'vue-property-decorator';
import template from './Faq.Template.vue';
import { Profils } from '@/shared/enums';
import { ApiService } from '@/services/base/ApiService';
import { SeuilMinimumOperation } from '@/models';
import { AxiosResponse } from 'axios';

@Component({
    ...template,
    name: 'Faq',
})
export default class Faq extends Vue {

    // Permet de faire en sorte que le template ait accès à l'enum.
    public Profils = Profils;

    /**
     * Seuil minimum des opérations actif.
     */
    public seuilActif: string | number = '[seuil en cours]';

    /**
     * Récupération du seuil actif.
     */
    public mounted() {
        const seuilApi = new ApiService<SeuilMinimumOperation>('seuilMinimumOperation/obtenirSeuilActif');
        return new Promise<AxiosResponse<SeuilMinimumOperation>>(() => {
            return seuilApi.getWhereSingle(undefined).then((response) => {
                if (response.data && response.data.valeur) {
                    this.seuilActif = response.data.valeur;
                }
            });
        });
    }
}
